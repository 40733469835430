
import { defineComponent, reactive, toRefs, ref, onMounted } from 'vue'
import { ElMessage } from 'element-plus'
import {
  getdemoImeiManageList,
  editdemoImeiManage,
  addDemoImeiManage,
  isEditAuthByCode
} from '@/api/index'
interface deviceDataF {
  loading: boolean
  rules: any
  pageSize: number
  pageSizes: Array<number>
  currentPage: number
  total: number
  rowsData: Array<any>
  editFlag: boolean
  editForm: any
  searchDataAny: any //搜索条件
  devColorOption:any
  devIconOption:any
  enableOption:any
}

export default defineComponent({
  name: 'demoImeiManageTableTable',
  components: {},
  setup() {

      const myRef = ref()

    const deviceData: deviceDataF = reactive({
      loading: true,
      editFlag: false,
      editForm: {},
      searchDataAny: {}, //搜索条件
      rowsData: [],
      total: 0,
      pageSize: 10,
      pageSizes: [10, 30, 50, 100],
      currentPage: 1,
      enableOption:[{
          value: 0,
          label: '禁用',
        },
        {
          value: 1,
          label: '启用',
        }],
      devColorOption: [
        {
          value: 1,
          label: '蓝色',
        },
        {
          value: 2,
          label: '黄色',
        },
        {
          value: 3,
          label: '黑色',
        },
        {
          value: 4,
          label: '白色',
        },
        {
          value: 9,
          label: '其他',
        }
        ],
        devIconOption: [
        {
          value: 0,
          label: '默认',
        },{
          value: 1,
          label: '人',
        },
        {
          value: 2,
          label: '宠物',
        },
        {
          value: 3,
          label: '自行车',
        },
        {
          value: 4,
          label: '摩托车',
        },
        {
          value: 5,
          label: '小汽车',
        },
        {
          value: 6,
          label: '货车',
        },
        {
          value: 7,
          label: '行李',
        }
      ],
      rules: {
          imeiKey: [{ required: true, message: '请输入 真实IMEI' , trigger: 'blur' }],
         imeiValue: [{ required: true, message: '请输入 对照IMEI', trigger: 'blur' }],
         devName: [{ required: true, message: '请输入 设备名', trigger: 'blur' }],
         cardNum: [{ required: true, message: '请输入 车牌号', trigger: 'blur' }],
         devColor: [{ required: true, message: '请选择 车牌颜色', trigger: 'blur' }],
         devIcon: [{ required: true, message: '请选择 定位图标', trigger: 'blur' }],
         enable: [{ required: true, message: '请选择 是否启用', trigger: 'blur' }],
         demoAccount: [{ required: true, message: '请输入 演示账号', trigger: 'blur' }],
      },
    })
    const data = toRefs(deviceData)
    onMounted(() => {
          setTimeout(function(){
              getList()
          },50);
    })


    //编辑
    const editorFun = (e: any) => {
      let obj: any = {}
      Object.assign(obj, e)
      deviceData.editForm = obj
      deviceData.editForm.isEdit = true      
      deviceData.editFlag = true
      }
      
      const addFun = () => {
          deviceData.editForm = { isEdit: false };
      deviceData.editFlag = true
    }
    
    const editSave = async () => {
      myRef.value.validate(async (valid: any) => {
         if (valid) {
         deviceData.loading = true
          let array: any = {}
          Object.assign(array, deviceData.editForm)
          let fun = addDemoImeiManage
          if (deviceData.editForm.isEdit) {
            fun = editdemoImeiManage
          }
          fun(array).then((res: any) => {
            let { code } = res
            if (code == 0) {
              deviceData.editFlag = false
              getList()
            } else {
              ElMessage.error(res.data)
            }
          })
        } else {
          return false
        }
      })
      }
    

    
    const search = () => {
      deviceData.currentPage = 1
      getList()
    }


    const getList = async () => {
      deviceData.searchDataAny.length = deviceData.pageSize
      deviceData.searchDataAny.page = deviceData.currentPage
      deviceData.loading = true
      await getdemoImeiManageList(deviceData.searchDataAny).then(
        (res: any) => {
          if (res.code == 0) {
              if(res.data!=null && res.data.list!=null){
                  deviceData.rowsData = res.data.list
                  deviceData.total = res.data.total
              }else{
                  deviceData.rowsData = [];
                  deviceData.total = 0;
                  ElMessage.success('加载成功，数据为空！');
              }
            
          setTimeout(function(){
              deviceData.loading = false
          },100);
          
          }else if(res.errMsg!=null){
            ElMessage.error(res.errMsg);
              deviceData.loading = false
        }else{
            ElMessage.error("加载失败");
              deviceData.loading = false
        }
        }
      )
    }

    const handleSizeChange = (val: any) => {
      //设置当前页显示几条数据
      deviceData.pageSize = val
      getList()
    }
    const handleCurrentChange = (val: any) => {
      //当前页码
      deviceData.currentPage = val
      getList()
    }


      const formatDevColor = (val: any) => {
          for (let devColor of deviceData.devColorOption){
                if (devColor.value == val){
                    return devColor.label;
            }
        }
      }
    
      const formatDevIcon = (val: any) => {
          for (let devIcon of deviceData.devIconOption) {
              if (devIcon.value == val) {
                  return devIcon.label;
              }
          }
      }
    //


    return {
      ...data,
      addFun,
      myRef,
      editSave,
        search,
      editorFun,
      handleSizeChange,
      handleCurrentChange,
    isEditAuthByCode,formatDevColor,formatDevIcon
    }
  },
})
